import { render, staticRenderFns } from "./DemandList.vue?vue&type=template&id=3e2e120c&scoped=true&"
import script from "./DemandList.vue?vue&type=script&lang=js&"
export * from "./DemandList.vue?vue&type=script&lang=js&"
import style0 from "./DemandList.vue?vue&type=style&index=0&id=3e2e120c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2e120c",
  null
  
)

export default component.exports