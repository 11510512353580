<template>
  <div>
    <div class="title">需求列表</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
      </div>
    </div>

    <div class="post" v-for="item in jobList" :key="item.id">
      <div class="post_info">
        <div class="post_info_title">
          {{ item.name }}<span>报酬：￥1000.00</span>
        </div>
        <div class="post_info_date">
          时间要求：{{ item.finish_time }} <span>前完成</span>
        </div>
        <div class="post_info_content">验收要求：{{item.desc}}</div>
      </div>
      <div class="post_progress">
        <div class="post_progress_title">当前需求发布</div>
        <div class="post_progress_content">
          <div>
            <span>{{ item.count_order_apply }}</span
            >人申请
          </div>
          <div><span>{{ item.count_order_cooperation }}</span>人合作中</div>
          <div><span>{{ item.count_order_completed }}</span>人已验收</div>
        </div>
      </div>
      <div class="post_btns">
        <div class="custom_button plain no_select">
          编辑
        </div>
        <div class="custom_button plain no_select">
          <!-- {{ item.status === 1 ? "停止" : "开启" }} -->停止
        </div>
      </div>
    </div>
    <div v-if="jobList.length === 0" style="margin: 30px 20px;">暂无数据</div>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      // 搜索参数
      search: {
        keyword: "",
      },
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      jobList: [],
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("flexibleEmployment", ["getDemandList"]),

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword;
      }
      return () => {
        params.page = this.page.page;
        let loading = this.$loading();
        this.getDemandList(params)
          .then((res) => {
            console.log(res);
            this.jobList = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    /**
     * 关闭职位
     */
    toStop(id, isStop) {
      let loading = this.$loading();
      this.getJobDisable({
        id,
        isStop,
      })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.$nextTick(() => {
            loading.close();
            this.loadData();
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(e.message);
          this.$nextTick(() => {
            loading.close();
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.post {
  background: #ffffff;
  border: 1px solid #f0f4f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  margin: 20px 0;

  .post_info {
    .post_info_title {
      font-size: 20px;
      font-weight: bold;
      color: #1c2438;

      > span {
        margin-left: 25px;
        font-size: 14px;
        color: #f94d09;
      }
    }

    .post_info_date {
      font-size: 14px;
      color: #495060;
      margin: 15px 0;
    }

    .post_info_content {
      font-size: 14px;
      color: #495060;
      display: flex;
      align-items: center;
      width: 400px;
    }
  }

  .post_progress {
    border-left: 1px solid #e1e6eb;
    padding-left: 30px;
    .post_progress_title {
      font-size: 16px;
      color: #1c2438;
    }

    .post_progress_content {
      margin-top: 20px;
      display: flex;
      font-size: 14px;
      color: #80848f;
      > div {
        margin-right: 30px;

        span {
          color: #4db9d5;
        }
      }
    }
  }

  .post_btns {
    display: flex;

    .custom_button {
      width: 58px;
      height: 32px;
      line-height: 32px;

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>
